import { useMediaQuery } from 'react-responsive'

import { TitleBackground, Section, FlexCol, Image, ProfileLink } from '../../layout/Elements'

export default function About() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Section darkMode isLargeScreen={isLargeScreen} isMobile={isMobile}>
      <h1 id="propos">À propos</h1>
      <TitleBackground darkMode>À propos</TitleBackground>

      <FlexCol isLargeScreen={isLargeScreen}>
        <div
          style={{
            flex: '1 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src="/profile.png" />

          <ProfileLink
            href="https://www.linkedin.com/in/quentinfremeaux/"
            target="_blank"
            rel="noreferrer"
          >
            <h2>Quentin Frémeaux</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="auto"
              height="4rem"
              fill="#fafbf8"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none" />
              <rect x="36" y="36" width="184" height="184" rx="8" opacity="0.2" />
              <rect
                x="36"
                y="36"
                width="184"
                height="184"
                rx="8"
                fill="none"
                stroke="#fafbf8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <line
                x1="120"
                y1="112"
                x2="120"
                y2="176"
                fill="none"
                stroke="#fafbf8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <line
                x1="88"
                y1="112"
                x2="88"
                y2="176"
                fill="none"
                stroke="#fafbf8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <path
                d="M120,140a28,28,0,0,1,56,0v36"
                fill="none"
                stroke="#fafbf8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <circle cx="88" cy="80" r="12" />
            </svg>
          </ProfileLink>
          <h3>Entrepreneur | Développeur Expert JS</h3>
        </div>
        <div style={{ flex: '1 0' }}>
          <h3>Entrepreneur | Fondateur Shareadesk</h3>
          <span>
            Fort de mon expérience entrepreneuriale et de la conception de{' '}
            <strong>
              {' '}
              <a
                href="https://www.youtube.com/watch?v=5C73jn7HQ7Q"
                target="_blank"
                rel="noreferrer"
              >
                l&apos;application primée Shareadesk
              </a>
            </strong>
            , je transforme vos idées en prototypes efficaces en quelques jours grâce à la méthode
            Design Sprint de Jake Knapp de Google Ventures.
          </span>
          <br />
          <br />
          <h3>Développeur Expert Javascript</h3>
          <span>
            Bénéficiez de ma maîtrise en Javascript, fort de 7 ans d&apos;expérience et formé à
            l&apos;école 42. Je vous offre une guidance complète, de la conception à la livraison,
            tout en incorporant des méthodes agiles . Mon approche garantit qualité, coopération et
            évite les pièges coûteux tels que la Programmation Spaghetti ou les problèmes de Bus
            Factor. Transformez vos défis en succès avec mon expertise.
          </span>
        </div>
      </FlexCol>
    </Section>
  )
}
