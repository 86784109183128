import { useMediaQuery } from 'react-responsive'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import TerminalIcon from '@mui/icons-material/Terminal'
import Diversity3Icon from '@mui/icons-material/Diversity3'

import { TitleBackground, Section, Inline, Card } from '../../layout/Elements'

export default function Services() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Section
      darkMode
      isLargeScreen={isLargeScreen}
      isMobile={isMobile}
      style={{
        backgroundColor: '#101010',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <h1 id="services">Services</h1>
      <TitleBackground darkMode>Services</TitleBackground>

      <Card style={{ marginRight: isLargeScreen ? '20rem' : 0 }}>
        <Inline>
          <DesignServicesIcon fontSize="large" style={{ color: '#b827fc' }} />
          <h2 style={{ color: '#b827fc' }}>De l’idée au prototype en 5 jours</h2>
        </Inline>
        <br />
        Grâce à la méthode <strong>Design Sprint</strong>, transformez vos concepts en maquettes
        testées et validées, idéales pour fédérer vos équipes, engager vos utilisateurs et
        convaincre vos investisseurs.
      </Card>
      {/* <Circle right style={{ top: isMobile ? '30%' : '35%', left: '13%' }} /> */}
      <Card>
        <Inline>
          <TerminalIcon fontSize="large" style={{ color: '#2c90fc' }} />
          <h2 style={{ color: '#2c90fc' }}>Expertise Développement Web & Mobile</h2>
        </Inline>
        <br />
        Avec plus de7 ans d’expérience en Javascript et Node.js, assurez un développement robuste et
        maintenable grâce à des technologies standardisées comme{' '}
        <strong>ReactJS et Typescript</strong>, conformes aux meilleures pratiques (AirBnB Style
        Guide).
      </Card>
      {/* <Circle style={{ top: isMobile ? '60%' : '65%', right: '13%' }} /> */}
      <Card style={{ marginLeft: isLargeScreen ? '20rem' : 0 }}>
        <Inline>
          <Diversity3Icon fontSize="large" style={{ color: '#fd1892' }} />
          <h2 style={{ color: '#fd1892' }}>Pilotage Agile et Garantie de Qualité</h2>
        </Inline>
        <br />
        Grace aux techniques Scrum, appliquez des <strong>méthodes agiles</strong> avec des sprints
        de 2 à 4 semaines et un suivi rigoureux (revue de code, tests), garantissant un projet
        structuré et performant.
      </Card>
    </Section>
  )
}
