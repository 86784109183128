import './styles.css'

function MentionsLegales() {
  return (
    <section style={{ margin: '0 auto', padding: '10rem 1rem 5rem', maxWidth: '800px' }}>
      <body className="c11 doc-content">
        <p className="c1" id="h.gjdgxs">
          <span className="c15" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c16">
          <span className="c0">MENTIONS L&Eacute;GALES</span>
        </p>
        <p className="c16 c19">
          <span className="c8" />
        </p>
        <p className="c7">
          <span className="c2 c13">
            Conform&eacute;ment aux dispositions de la loi n&deg; 2004-5
          </span>
          <span className="c3 c13">
            75 du 21 juin 2004 pour la confiance en l&#39;&eacute;conomie num&eacute;rique, il est
            pr&eacute;cis&eacute; aux utilisateurs du site Design Horizon l&#39;identit&eacute; des
            diff&eacute;rents intervenants dans le cadre de sa r&eacute;alisation et de son suivi.
          </span>
        </p>
        <p className="c1">
          <span className="c12" />
        </p>
        <p className="c7">
          <span className="c8">Edition du site </span>
        </p>
        <p className="c1">
          <span className="c5" />
        </p>
        <p className="c7 c17">
          <span className="c2">Le pr&eacute;sent site, accessible </span>
          <span className="c3">&agrave; l&rsquo;URL www.designhorizon.io</span>
          <span className="c3">&nbsp;</span>
          <span className="c3">(le &laquo; Site &raquo;), est &eacute;dit&eacute; par :</span>
        </p>
        <p className="c6">
          <span className="c3 c18">
            <br />
          </span>
          <span className="c3">Design Horizon &amp; Development , </span>
          <span className="c3">soci&eacute;t&eacute; au capital de 1000</span>
          <span className="c3">&nbsp;</span>
          <span className="c2">
            euros, inscrite au R.C.S. de PARIS sous le num&eacute;ro 940 062 268 R.C.S. Paris, dont
            le si&egrave;ge social est situ&eacute; au 60 rue Fran&ccedil;ois 1er 75008 Paris,
            repr&eacute;sent&eacute;(e) par Quentin FREMEAUX d&ucirc;ment habilit&eacute;(e)
          </span>
        </p>
        <p className="c1 c9">
          <span className="c2" />
        </p>
        <p className="c6">
          <span className="c3">
            Le num&eacute;ro individuel TVA de l&rsquo;&eacute;diteur est : FR86940062268.
          </span>
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c7">
          <span className="c8">H&eacute;bergement</span>
        </p>
        <p className="c6">
          <span className="c2">
            <br />
          </span>
          <span className="c3">
            Le Site est h&eacute;berg&eacute; par la soci&eacute;t&eacute; OVH SAS, situ&eacute; 2
            rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact t&eacute;l&eacute;phonique
            ou email : 1007).
          </span>
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c7">
          <span className="c8">Directeur de publication </span>
        </p>
        <p className="c6">
          <span className="c2">
            <br />
          </span>
          <span className="c3">Le Directeur de la publication du Site est Quentin FREMEAUX .</span>
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
        <p className="c7">
          <span className="c8">Nous contacter </span>
        </p>
        <p className="c6">
          <span className="c2">
            <br />P
          </span>
          <span className="c2">ar t&eacute;l&eacute;phone&nbsp;: +33678092083</span>
        </p>
        <p className="c6">
          <span className="c2">Par email&nbsp;: hello@designhorizon.io</span>
        </p>
        <p className="c6">
          <span className="c3">Par courrier&nbsp;: 60 rue Fran&ccedil;ois 1er 75008 Paris</span>
        </p>
        <p className="c1 c9">
          <span className="c4" />
        </p>
        <p className="c7">
          <span className="c8">Donn&eacute;es personnelles</span>
        </p>
        <p className="c1">
          <span className="c5" />
        </p>
        <p className="c6">
          <span className="c3">Le traitement de vos donn&eacute;es &agrave; cara</span>
          <span className="c3">
            ct&egrave;re personnel est r&eacute;gi par notre Charte du respect de la vie
            priv&eacute;e, disponible depuis la section{' '}
          </span>
          <span className="c3">
            &quot;Charte de Protection des Donn&eacute;es Personnelles&quot;
          </span>
          <span className="c3">,</span>
          <span className="c3">&nbsp;</span>
          <span className="c2">
            conform&eacute;ment au R&egrave;glement G&eacute;n&eacute;ral sur la Protection des
            Donn&eacute;es 2016/679 du 27 avril 2016 (&laquo;RGPD&raquo;).
          </span>
        </p>
        <p className="c1 c9">
          <span className="c2" />
        </p>
        <p className="c7">
          <span className="c3">G&eacute;n&eacute;ration des mentions l&eacute;gales par </span>
          <span className="c3 c10">
            <a
              className="c14"
              href="https://www.google.com/url?q=https://www.legalstart.fr/&amp;sa=D&amp;source=editors&amp;ust=1738071688725690&amp;usg=AOvVaw20C88Jh6z6uK26anKM8hSg"
            >
              Legalstart
            </a>
          </span>
          <span className="c3">.</span>
        </p>
        <p className="c1">
          <span className="c2" />
        </p>
      </body>
    </section>
  )
}

export default MentionsLegales
