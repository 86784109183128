import styled from 'styled-components/macro'
import { CSSProperties } from 'react'

export const TitleBackground = styled.div<{ size?: CSSProperties['fontSize']; darkMode?: boolean }>`
  position: absolute;
  z-index: 0;
  margin-top: -1rem;
  font-size: ${props => props.size || '15vw'};
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${props =>
    props.darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'};
`
export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
  min-height: 600px;
`
export const Canvas = styled.canvas`
  background: black;
  height: 100%;
  width: 100%;
`
export const Arrow = styled.a`
  position: absolute;
  top: 75%;
  right: 10%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin-top: 0;

  &:hover,
  &:focus,
  &:focus-within {
    margin-top: 2rem;
    border-image: none;
  }
`
export const Card = styled.div`
  z-index: 1;
  border-radius: 1rem;
  backdrop-filter: blur(40px);
  box-shadow: 0px 1.6733156776526186px 1.6733156776526186px 0px rgba(0, 0, 0, 0),
    0px 3.984206390969505px 3.984206390969505px 0px rgba(0, 0, 0, 0.00996),
    0px 7.259321882820145px 7.259321882820145px 0px rgba(0, 0, 0, 0.01815),
    0px 12.068385491961365px 12.068385491961365px 0px rgba(0, 0, 0, 0.03017),
    0px 19.50049274209897px 19.50049274209897px 0px rgba(0, 0, 0, 0.04875),
    0px 31.901418213351462px 31.901418213351462px 0px rgba(0, 0, 0, 0.07975),
    0px 54.96035212570371px 54.96035212570371px 0px rgba(0, 0, 0, 0.1374),
    0px 100px 100px 0px rgba(0, 0, 0, 0.25);

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block: 0;
    padding: 0%;
  }
`
export const Circle = styled.div<{ right?: boolean; darkMode?: boolean }>`
  width: 30rem;
  height: 30rem;
  border: 2rem solid transparent;
  border-radius: ${props => (props.right ? '50% 0 0 50%' : '0 50% 50% 0')};
  background-image: linear-gradient(
      ${props => (!props.darkMode ? '#201f1f' : '#fafbf8')},
      ${props => (!props.darkMode ? '#201f1f' : '#fafbf8')}
    ),
    linear-gradient(
      to ${props => (props.right ? 'right' : 'left')},
      #b827fc 0%,
      #2c90fc 50%,
      #fd1892 100% transparent 50.1%,
      transparent 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: absolute;
  z-index: 0;
`
export const ContactLink = styled.a<{ isMobile?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  width: fit-content;
  border-width: 0.25rem;
  font-size: ${props => (props.isMobile ? '1.2rem' : '1.5rem')};

  &:hover,
  &:focus,
  &:focus-within {
    border-width: 0.25rem;
  }
`
export type SectionProps = {
  darkMode?: boolean
  isLargeScreen: boolean
  isMobile: boolean
  fullWidth?: boolean
}
export const Section = styled.div<SectionProps>`
  background: ${props => (props.darkMode ? '#151515' : '##eff2f7')};
  color: ${props => (!props.darkMode ? '#171717' : '#f8f9fc')};
  font-family: ${props => props.theme.fonts[0]};
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: 600;
  padding: ${props =>
    props.isMobile ? '3rem 1rem' : props.isLargeScreen ? '3rem 7rem' : '3rem 4rem'};
  padding-left: ${props => (props.fullWidth ? '0' : '4rem')};
  padding-right: ${props => (props.fullWidth ? '0' : '4rem')};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 5rem;
  overflow: hidden;

  font-family: ${props => props.theme.fonts[1]};
  font-size: 1rem;

  strong {
    font-weight: 800;
    border: 0px solid transparent;
    border-image: linear-gradient(to left, #b827fc 0%, #2c90fc 50%, #fd1892 100%);
    border-image-slice: 1;
    border-bottom-width: 0.15rem;
  }

  h1 {
    padding-top: 7rem;
    font-weight: 600;
    font-size: ${props => (props.isMobile ? '2rem' : '3rem')};
    line-height: 1.2;
    margin: 0 0 2rem 0;
    z-index: 1;
    color: inherit;

    border: 0px solid transparent;
    border-image: linear-gradient(to right, #b827fc 0%, #2c90fc 50%, #fd1892 100%);
    border-image-slice: 1;
    border-bottom-width: 0.5rem;
  }
  h2 {
    font-size: ${props => (props.isMobile ? '1.4rem' : '1.6rem')};
    line-height: 1;
    color: inherit;
  }
  h3 {
    color: ${props => (!props.darkMode ? '#3a3a3a' : '#c3c3c3')};
    font-size: ${props => (props.isMobile ? '1.4rem' : '1.6rem')};
    color: inherit;
  }
  h4 {
    color: ${props => (!props.darkMode ? '#3a3a3a' : '#c3c3c3')};
    font-size: ${props => (props.isMobile ? '1rem' : '1.2rem')};
    color: inherit;
  }

  ${Card} {
    background: ${props => (!props.darkMode ? '#00000010' : '#ffffff10')};
    color: ${props => (!props.darkMode ? '#171717' : '#f8f9fc')};

    width: ${props => (props.isLargeScreen ? '60%' : '100%')};
    padding: ${props => (props.isLargeScreen ? '2rem' : '1.5rem')};
  }
`
export const Helper = styled.span`
  &:hover {
    text-decoration: underline;
  }
`
export const Inline = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 1rem 0;
`
export const Image = styled.img`
  border-radius: 50%;
  border: 0.4rem solid #c1dae3;
  width: 20rem;
  height: 20rem;
`
export const ProfileLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  h2 {
    margin: 0;
  }
`
export const FlexCol = styled.div<{ isLargeScreen: boolean }>`
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-direction: ${props => (props.isLargeScreen ? 'row' : 'column')};
`
export const Col = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`
